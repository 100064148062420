import { useQuery } from "@apollo/client";
import { Error } from "@components/ErrorHandler";
import { HeroCarousel, StyledLink } from "@components/HeroCarousel";
import { Image } from '@components/Image';
import { Loader } from "@components/Loader";
import { Spacer } from "@components/Spacer";
import { TestimonialsCarousel } from "@components/Testimonials";
import { COLLABS, HOMEPAGE } from "@graphql/queries";
import { device, pxToRem } from "@libs/index";
import { Fade, Flip } from "react-reveal";
import styled from "styled-components/macro";

const HomeHeroContainer = styled.main`
  position: relative;
  min-height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 0;

  @media ${device.tablet} {
    margin-top: 2rem;
  }
`;

const GridContainer = styled.div`
  display: flex;
  min-width: 100%;
  flex: 1;
  gap: ${pxToRem(18)};
  @media ${device.mobile} {
    max-height: ${pxToRem(1000)};
  }
`;

const GridContainer1 = styled.div`
  flex-basis: 33%;
  img {
    max-width: ${pxToRem(534)};
    height: ${pxToRem(667)};
  }
  @media ${device.mobile} {
    flex-basis: 50%;
    img {
      max-width: 100%;
      height: 100%;
      margin-top: ${pxToRem(-63)};
      max-height: 243px;
    }
  }
`;
const GridContainer2 = styled.div`
  flex-basis: 33%;
  display: block;

  img {
    max-width: ${pxToRem(534)};
    height: ${pxToRem(666)};
  }

  @media ${device.mobile} {
    display: none;
  }
`;
const GridContainer3 = styled.div`
  flex-basis: 33%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  img {
    max-width: ${pxToRem(261)};
    height: ${pxToRem(391)};
  }
  @media ${device.mobile} {
    flex-basis: 50%;
    img {
      max-width: 100%;
      margin-top: 20px;
      height: 70%;
      max-height: ${pxToRem(800)};
    }
  }
`;

const TextContainer = styled.div`
  height: ${pxToRem(125)};
  font-size: ${({ theme }) => theme.fontSize.step3};
  font-weight: 700;
  margin: ${pxToRem(43)} 0 ${pxToRem(-43)};
  line-height: 1.1;
`;

const StyledWorksLink = styled(StyledLink)`
  position: unset;
  width: fit-content;
  margin-left: auto;
  margin-top: ${pxToRem(55)};
  @media ${device.mobile} {
    margin-left: unset;
  }
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSize.step3};
  font-weight: 700;
`;

const Grid = styled.div`
  display: grid;
  margin: ${pxToRem(86)} 0;
  gap: ${pxToRem(70)} ${pxToRem(140)};
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(150px, 1fr));

  @media ${device.tablet} {
    gap: ${pxToRem(70)} ${pxToRem(70)};
    grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(85px, 1fr));
  }
`;

const Client = styled(Image)`
  background: transparent;
  width: 70%;
  max-width: 200px;
  margin: 0 auto;
`;

export const HomeContent = () => {
  const {
    loading,
    error,
    data: homepage,
  } = useQuery(HOMEPAGE, { variables: {} });

  const {
    loading: collabsLoading,
    error: collabsError,
    data: collabs,
  } = useQuery(COLLABS, { variables: {} });

  if (loading || collabsLoading) return <Loader />;
  if (error) return <Error />;

  return (
    homepage && (
      <HomeHeroContainer id="home-content">
        <HeroCarousel homepageData={homepage.homepageCarousels.nodes[1].gallery} />
        <Spacer size={150} />
        <Fade bottom>
          <TestimonialsCarousel />
        </Fade>
        <Spacer size={200} />

        <GridContainer>
          <GridContainer1>
            <TextContainer />
            <Flip left>
              <Image
                src={
                  homepage.homepageCarousels.nodes[0].gallery.gallery[0].sourceUrl
                }
                alt=""
              />
            </Flip>
          </GridContainer1>
          <GridContainer2>
            <Flip left>
              <Image

                src={
                  homepage.homepageCarousels.nodes[0].gallery.gallery[1].sourceUrl
                }
                alt=""
              />
            </Flip>
            <TextContainer>About Food and stories </TextContainer>
          </GridContainer2>

          <GridContainer3>
            <TextContainer />
            <div>
              <Flip left>
                <Image
                  src={
                    homepage.homepageCarousels.nodes[0].gallery.gallery[2].sourceUrl
                  }
                  alt=""
                />
              </Flip>
              <StyledWorksLink to="/projects#food">Go to works</StyledWorksLink>
            </div>
          </GridContainer3>
        </GridContainer>

        <Spacer size={142} />
        {!collabsError && collabs && (
          <Fade bottom>
            <StyledTitle>I worked with</StyledTitle>
            <Grid>
              {collabs.collabs.nodes.map(
                (client: any, index: number) => {
                  return (
                    <Client
                      key={index}
                      src={client.featuredImage.node.sourceUrl}
                    />
                  );
                }
              )}
            </Grid>
          </Fade>
        )}
      </HomeHeroContainer>
    )
  );
};
