import styled from "styled-components/macro";
import { pxToRem } from "../libs";

const BaseContainerStyled = styled.div`
  height: 100%;
  width: 100%;
`;

export const BaseContainer: React.FC = ({ children }) => {
  return <BaseContainerStyled>{children}</BaseContainerStyled>;
};

interface FlexContainerProps {
  align?: "center" | "flex-start" | "flex-end";
}

const FlexContainerStyled = styled.div<FlexContainerProps>`
  display: flex;
  position: relative;
  min-height: ${pxToRem(300)};
  min-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: ${(props) => props.align};
`;

export const FlexContainer: React.FC<FlexContainerProps> = ({
  children,
  align = "center",
}) => <FlexContainerStyled align={align}>{children}</FlexContainerStyled>;
