import styled from "styled-components/macro";
import { pxToRem } from "../libs";

interface SpacerProps {
  size: number;
}

const SpacedDiv = styled.div<SpacerProps>`
  height: ${({ size }) => pxToRem(size)};
`;

export const Spacer: React.FC<SpacerProps> = ({ size = 71 }: SpacerProps) => (
  <SpacedDiv size={size} />
);
