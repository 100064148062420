import { Image } from '@components/Image';
import styled from "styled-components/macro";
import { device, pxToRem } from "../../libs";

export const ProjectCardStyled = styled.div<{ image: string }>`
  height: ${pxToRem(650)};
  width: 100%;
  background: ${({ image }) => `url(${image})no-repeat top center`};
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.text};
  position: relative;

  @media ${device.mobile} {
    height: ${pxToRem(850)};
  }

  &:after {
    position: absolute;
    content: attr(data-name);
    bottom: ${pxToRem(-50)};
    border-bottom: 1px solid black;
    padding: 0 ${pxToRem(27)} 0 0;
    line-height: 2.5;
    font-size: ${({ theme }) => theme.fontSize.step1};

    @media ${device.desktop} {
      bottom: ${pxToRem(-70)};
    }
    @media ${device.mobile} {
      bottom: ${pxToRem(-85)};
      line-height: 2;
    }
  }

  &:hover::before {
    background: rgba(0, 0, 0, 0.3);
    content: "";
    cursor: pointer;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  &:hover::after {
    position: absolute;
    inset: 0;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSize.step5};
    cursor: pointer;
    text-align: center;
    line-height: 1.1;
  }
`;

export const ProjectDetailsWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.text};
  gap: ${pxToRem(100)};
  padding: 0 8.75rem;
  align-items: flex-end;
  margin-bottom: ${pxToRem(125)};
  box-sizing: border-box;
  justify-content: space-evenly;

  @media ${device.desktop} {
    gap: ${pxToRem(80)};
    padding: 0 4.75rem;
  }

  @media ${device.tablet} {
    gap: 0;
    align-items: flex-start;
  }

  @media ${device.mobile} {
    gap: ${pxToRem(80)};
    padding: 0 2rem;
  }
`;

export const ProjectMainPicture = styled(Image)`
  max-width: 60%;
  width: auto;
  max-height: ${pxToRem(750)};

  @media ${device.desktop} {
    width: 50%;
    flex-basis: 50%;
  }

  @media ${device.tablet} {
    display: none;
  }
`;
export const ProjectDescriptionWrapper = styled.div`
  width: 30%;
  flex-basis: 30%;
  margin-bottom: ${pxToRem(100)};

  @media ${device.desktop} {
    width: 50%;
    flex-basis: 50%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-basis: 100%;
  }
`;
export const ProjectTitle = styled.h2`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.step5};
`;
export const ProjectDescription = styled.span``;

export const ProjectGalleryWrapper = styled.div`
  width: 100%;
`;

export const ProjectsCarouselContainer = styled.main`
  height: auto;
`;

export const ProjectNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8.75rem;
  margin-top: 50px;
  cursor: pointer;
`;
export const Btn = styled.div``;
