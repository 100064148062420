import {
  ProjectEntity,
  ProjectEntityResponseCollection, UploadFileRelationResponseCollection
} from ".";

export interface ProjectCardProps {
  project: ProjectEntity;
}

export interface ProjectDetailsProps {
  project: any;
}

export enum PhotoCategories {
  FOOD = "food",
  ARCHITECTURE = "architecture",
  STILLLIFE = "still-life",
}

export interface TestimonialProps {
  details: any;
  isActive: boolean;
}

export interface TestimonialStyleProps {
  isActive: boolean;
}

export interface ProjectImageGalleryProps {
  gallery: UploadFileRelationResponseCollection;
}

export interface ProjectsCarouselProps {
  projects?: ProjectEntityResponseCollection;
}
