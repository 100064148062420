import { Image } from "@components/Image";
import { TestimonialStyleProps } from "@customTypes/custom";
import styled from "styled-components/macro";
import { device, pxToRem } from "../../libs";

export const TestimonialContainer = styled.div<TestimonialStyleProps>`
  position: relative;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.15)};
  font-size: ${({ theme }) => theme.fontSize.step0};
  &:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

export const TestimonialHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.step2};
  margin-bottom: ${pxToRem(16)};
  font-weight: 700;
`;

export const TestimonialPicture = styled(Image)`
  border-radius: 50%;
  height: ${pxToRem(102)};
  aspect-ratio: 1/1;
  margin-right: ${pxToRem(28)};
`;

export const TestimonialBody = styled.span`
  font-size: ${({ theme }) => theme.fontSize.step0};
  font-weight: normal;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.step1};
  }
`;

export const Quote = styled.span`
  quotes: "“" "”";

  &:before,
  &:after {
    font-weight: bold;
    font-size: ${pxToRem(150)};
    height: 100px;
    width: 100px;
    color: ${({ theme }) => theme.colors.text};
    font-family: Georgia, "Times New Roman", Times, serif;
    content: open-quote;
    height: 100px;
    width: 100px;
    margin: 0 0 ${pxToRem(-40)} ${pxToRem(-45)};
    z-index: 1;
    position: relative;
    display: block;

    @media ${device.tablet} {
      display: none;
    }
  }

  &:after {
    content: close-quote;
    margin: ${pxToRem(-25)} ${pxToRem(-30)} ${pxToRem(30)} auto;
  }
`;

export const TestimonialsCarouselContainer = styled.main`
  position: relative;

  .swiper {
    height: 100%;
    width: 100%;
    position: unset;

    .swiper-slide {
      transform: scale(0.8);
      margin-top: -60px;
      &.swiper-slide-active {
        margin-top: 0;
        transform: scale(1);
      }
    }

    .swiper-pagination-bullets {
      position: absolute;
      left: unset;
      bottom: 0;
      right: 0;
      width: ${pxToRem(400)};
      display: flex;
      align-items: center;

      .swiper-pagination-bullet {
        width: ${pxToRem(400 / 3)};
        border-radius: 0;
        height: 2px;
        margin: 0;

        &.swiper-pagination-bullet-active {
          background: black;
          height: 4px;
          left: -2px;
        }
      }
  }
    }
  }

  @media ${device.desktop} {
    .swiper {
      width: 100%;

      .swiper-pagination-bullets {
        bottom: ${pxToRem(50)};
      }
    }
  }
  @media ${device.tablet} {
    .swiper {
      width: 100%;

      .swiper-pagination-bullets {
        bottom: ${pxToRem(-80)};
      }
    }
  }

`;
