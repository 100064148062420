import styled, { keyframes } from "styled-components";
const COLOR = `33,33,33`;
const pulseAnimation = keyframes`
    40% {
        transform: scale(1.1);
        box-shadow: 0 0 0 20px rgba(${COLOR}, 0.3);
    }
    80% {
        transform: scale(1);
        box-shadow: 0 0 0 40px rgba(${COLOR}, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(${COLOR}, 0)
    }
`;

type WrapperProps = {
  fullscreen: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: ${({ fullscreen }) => (fullscreen ? "fixed" : "absolute")};
  inset: 0;
  z-index: 9;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Pulse = styled.div`
  height: 50px;
  width: 50px;
  background-color: rgba(${COLOR}, 0.6);
  border-radius: 50%;
  animation: ${pulseAnimation} 1.5s infinite;
`;

export const Loader = ({ fullscreen = true }) => (
  <Wrapper fullscreen={fullscreen}>
    <Pulse />
  </Wrapper>
);
