import { useQuery } from "@apollo/client";
import { FlexContainer } from "@components/BaseContainer";
import { Error } from "@components/ErrorHandler";
import { Loader } from "@components/Loader";
import { ProjectDetails } from "@components/Projects";
import { Scalars } from "@customTypes/index";
import { PROJECTDETAILS } from "@graphql/queries";
import { useParams } from "react-router-dom";

type ProjectDetailsParams = {
  id: Scalars["ID"];
};

export const ProjectDetailsContent = () => {
  const { id } = useParams<ProjectDetailsParams>();
  const {
    loading,
    error,
    data: projectDetails,
  } = useQuery(PROJECTDETAILS, {
    variables: { id },
  });

  if (error) return <Error />;

  return (
    <FlexContainer align="flex-start">
      {loading ? (
        <Loader fullscreen={false} />
      ) : (
        <ProjectDetails project={projectDetails.project} />
      )}
    </FlexContainer>
  );
};
