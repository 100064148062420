import { ProjectDetailsProps } from "@customTypes/custom";
import { ProjectCardStyled } from "./styles";

export const ProjectCard: React.FC<ProjectDetailsProps> = ({ project }) => {

  return (
    <ProjectCardStyled
      data-name={project.title}
      image={`${project.featuredImage.node.sourceUrl}`}
    ></ProjectCardStyled>
  );
};
