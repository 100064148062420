import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useInView } from "react-hook-inview";

const scrollAnimation = keyframes`
  0%   { transform: translateX(-.75rem); opacity: 1; }
  25%  { opacity: 1; }
  75%  { transform: translateX(.75em); opacity: 0; }
  100% { transform: translateX(0); opacity: 0; }
`;

const disappearingAnimation = keyframes`
  0%   { opacity: 1; }
  25%   { opacity: 1; }
  50%   { opacity: 1; }
  75%   { opacity: .5; }
  100% { opacity: 0; }
`;

const Wrapper = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  pointer-events: none;
  animation: ${disappearingAnimation} 5s;
`;
const Inner = styled.div`
  display: block;
  position: relative;
  width: 3em;
  height: 1.5em;
  border: 0.25em solid white;
  border-radius: 1em;
`;
const Scroller = styled.span`
  display: block;
  position: absolute;
  left: 50%;
  background: white;
  height: 0.5em;
  width: 0.5em;
  top: 0.6em;
  margin-left: -0.25em;
  border-radius: 50%;
  transform-origin: top center;
  backface-visibility: hidden;
  animation: ${scrollAnimation} 2s ease-out infinite;
`;

export const DragToSeeMore = () => {
  const storageValue = sessionStorage.getItem("adrianlungu-dragIconCounter");
  if (!storageValue) {
    sessionStorage.setItem("adrianlungu-dragIconCounter", `0`);
  }
  let storageValueInt = parseInt(storageValue ? storageValue : "0");

  const [ref, inView] = useInView();
  useEffect(() => {
    if (!inView) return;
    storageValueInt++;
    sessionStorage.setItem("adrianlungu-dragIconCounter", `${storageValueInt}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div ref={ref}>
      {inView && storageValueInt === 0 ? (
        <Wrapper>
          <Inner>
            <Scroller />
          </Inner>
          <h2>Drag to see more</h2>
        </Wrapper>
      ) : null}
    </div>
  );
};
