import { Image } from "@components/Image";
import { DragToSeeMore } from "@components/Testimonials/DragToSeeMore";
import { device } from "@libs/index";
import { useRef } from "react";
import styled from "styled-components/macro";
import useDragScroll from "use-drag-scroll";

const ProjectsCarouselContainer = styled.main`
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  position: relative;

  @media ${device.mobile} {
    max-height: 500px;
  }
`;

const SwiperSlideStyled = styled(Image)`
  max-height: 700px;
  margin-right: 10px;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  &:last-child {
    margin-right: 0;
  }
  @media ${device.mobile} {
    max-height: 500px;
  }
`;

export const ProjectImageGallery = ({ gallery }: any) => {
  const ref = useRef(null);
  useDragScroll({
    sliderRef: ref,
  });

  return (
    <ProjectsCarouselContainer ref={ref}>
      <DragToSeeMore />
      {gallery.map((image: any, i: number) => (
        <SwiperSlideStyled
          key={i}
          src={`${image.sourceUrl}`}
        />
      ))}
    </ProjectsCarouselContainer>
  );
};
