import { useQuery } from "@apollo/client";
import { FlexContainer } from "@components/BaseContainer";
import { Error } from "@components/ErrorHandler";
import { Loader } from "@components/Loader";
import { ProjectCard } from "@components/Projects/ProjectCard";
import { Spacer } from "@components/Spacer";
import { PhotoCategories } from "@customTypes/custom";
import { ProjectEntity, Scalars } from "@customTypes/index";
import { PROJECTS } from "@graphql/queries";
import { device, pxToRem } from "@libs/index";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { ProjectDetailsContent } from "./ProjectDetails";

const ProjectsWrapper = styled.div`
  margin: ${pxToRem(32)} 0 ${pxToRem(100)};
  @media ${device.tablet} {
    margin-top: 2rem;
  }
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(90)};
  grid-row-gap: ${pxToRem(90)};
  height: 100%;
  position: relative;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: ${pxToRem(150)};
  }
`;
const FilterCol = styled.div`
  margin-bottom: ${pxToRem(25)};
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  a {
    width: auto;
    display: inline;
    padding: ${pxToRem(1)} 0;
  }
  a:last-child::after {
    content: "";
  }
`;

const ProjectsCarouselContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
`;

const StyledNavLink = styled(NavLink)`
  font-size: ${({ theme }) => theme.fontSize.step1};
  &.active {
    font-weight: bold;
  }
  &:after {
    content: "/";
    width: ${pxToRem(4)};
    padding: 0 ${pxToRem(4)};
  }
`;

type ProjectDetailsParams = {
  id: Scalars["ID"];
};

export const ProjectsContent = () => {
  const location = useLocation();
  let { id } = useParams<ProjectDetailsParams>();
  const [allProjects, setAllProjects] = useState<ProjectEntity[]>();
  const [filteredProjects, setFilteredProjects] = useState<ProjectEntity[]>();
  const [currentFilter, setCurrentFilter] = useState<string>("");
  const [showProjectDetails, setShowProjectDetails] = useState<string | null>(
    null
  );

  const {
    loading,
    error,
    data: projects,
  } = useQuery(PROJECTS, { variables: {} });

  useEffect(() => {
    const body = document.querySelector("#root");
    if (!body) return;
    body.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!projects) return;
    setAllProjects(projects.projects.nodes || []);
    setFilteredProjects(projects.projects.nodes || []);
  }, [projects]);

  useEffect(() => {
    setShowProjectDetails(id);
  }, [id]);

  useEffect(() => {
    const filtered = allProjects?.filter((item: any) => {
      const urlFilter = location.hash.replace("#", "").replace("-", "").toUpperCase();
      setCurrentFilter(
        PhotoCategories[urlFilter as keyof typeof PhotoCategories]
      );

      const filterType = item.projecttype?.projecttype[0]
      return currentFilter === filterType;
    });
    setFilteredProjects(
      filtered && filtered.length > 0 ? filtered : allProjects
    );
  }, [allProjects, currentFilter, location]);

  if (loading) return <Loader />;
  if (error) return <Error />;

  return (
    <FlexContainer align="flex-start">
      {filteredProjects && (
        <ProjectsWrapper>
          <FilterCol>
            <FilterWrapper>
              {showProjectDetails ? (
                <StyledNavLink
                  to={{ hash: currentFilter, pathname: "/projects" }}
                  isActive={() => true}
                >
                  Back
                </StyledNavLink>
              ) : (
                <>
                  <StyledNavLink
                    to={{ hash: "#food" }}
                    isActive={() => {
                      return window.location.hash === "#food";
                    }}
                  >
                    Food
                  </StyledNavLink>
                  <StyledNavLink
                    to={{ hash: "#still-life" }}
                    isActive={() => {
                      return window.location.hash === "#still-life";
                    }}
                  >
                    Still Life
                  </StyledNavLink>
                  <StyledNavLink
                    to={{ hash: "#architecture" }}
                    isActive={() => {
                      return window.location.hash === "#architecture";
                    }}
                  >
                    Architecture
                  </StyledNavLink>
                </>
              )}
            </FilterWrapper>
          </FilterCol>
          {showProjectDetails ? (
            <ProjectsCarouselContainer>
              <ProjectDetailsContent />
            </ProjectsCarouselContainer>
          ) : (
            <Layout>
              {filteredProjects.map((project: any, index: number) => {
                return (
                  <Link to={`/projects/${project.id}`}>
                    <ProjectCard key={index} project={project} />
                  </Link>
                );
              })}
            </Layout>
          )}
          <Spacer size={71} />
        </ProjectsWrapper>
      )}
    </FlexContainer>
  );
};
