import ContactImage from "@assets/img/contacts.png";
import { Image } from "@components/Image";
import { Spacer } from "@components/Spacer";
import { device, pxToRem } from "@libs/index";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components/macro";

type Inputs = {
  name: string;
  email: string;
  message: string;
};

type FormStatus = "SUCCESS" | "FAILED" | "LOADING" | null;

interface FormFeedbackStatus {
  status: FormStatus;
}

const ContactWrapper = styled.main`
  display: flex;
  width: 100%;
  min-height: 86vh;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: initial;
  }
`;
const LeftColumn = styled.div`
  flex-basis: ${pxToRem(600)};
  padding: 0 0 0 ${pxToRem(120)};
  box-sizing: border-box;

  img {
    width: 80%;
    margin: ${pxToRem(64)} auto 0;
    display: initial;

    @media ${device.tablet} {
      margin: 0 auto;
      display: flex;
      width: 50%;
    }
  }

  @media ${device.desktop} {
    padding: 0 0 0 ${pxToRem(100)};
  }
  @media ${device.tablet} {
    padding: 0 0 0 ${pxToRem(0)};
  }
`;

const RightColumn = styled.div`
  padding: 0;
  box-sizing: border-box;
  flex-basis: calc(100% - ${pxToRem(800)});

  @media ${device.tablet} {
    flex-basis: 100%;
    margin-bottom: ${pxToRem(200)};
  }
`;

const LeftColumnWrapper = styled.div`
  max-width: ${pxToRem(550)};

  @media ${device.desktop} {
    max-width: ${pxToRem(600)};
  }

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const FormTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.step6};
  font-weight: bold;
  line-height: 1;
  padding: 0;
  margin: 0;
`;

const FormSubTitle = styled.h3`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.step1};
  margin-top: ${pxToRem(10)};
`;

const ContactTextWrapper = styled.div`
  max-width: ${pxToRem(340)};
  @media ${device.desktop} {
    max-width: ${pxToRem(300)};
  }
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const StyledForm = styled.form`
  position: relative;
`;
const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSize.step};
  font-weight: light;
  margin-bottom: ${pxToRem(8)};
  display: block;
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding: ${pxToRem(8)} 0;
  outline: none;
  font-size: ${({ theme }) => theme.fontSize.step0};
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding: ${pxToRem(8)} 0;
  height: ${pxToRem(150)};
  resize: vertical;
  outline: none;
  font-size: ${({ theme }) => theme.fontSize.step0};
  font-family: "Open Sans", sans-serif;
`;
const FormFieldWrapper = styled.div`
  margin-bottom: ${pxToRem(34)};
  &.error {
    ${StyledTextArea}, ${StyledInput}, ${StyledLabel} {
      border-color: red;
      color: red;
    }
  }
`;

const SubmitButton = styled.button`
  border: none;
  padding-top: ${pxToRem(15)};
  border-top: 2px solid ${({ theme }) => theme.colors.text};
  width: fit-content;
  min-width: ${pxToRem(85)};
  background: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: ${pxToRem(25)};
  margin-left: calc(100% - ${pxToRem(85)});
  color: ${({ theme }) => theme.colors.text};

  @media ${device.mobile} {
    margin-left: calc(100% - ${pxToRem(200)});
  }
`;

const FormFeedback = ({ status }: FormFeedbackStatus) => {
  switch (status) {
    case "LOADING":
      return <div>✉️ Sending email...</div>;
    case "SUCCESS":
      return <div>🚀 Congratulations! Your message has been sent!</div>;
    case "FAILED":
      return <div>🤯 Oh Snap! Something went wrong!</div>;
    default:
      return null;
  }
};

export const ContactContent: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [formState, setFormState] = useState<FormStatus>(null);

  useEffect(() => {
    const body = document.querySelector("#root");
    if (!body) return;
    body.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setFormState("LOADING");
    const body = {
      to: "adrian92.lu@gmail.com",
      subject: "Informazioni dal form del sito",
      form: data,
    };

    fetch("https://backend-utils.onrender.com/mailer/sendMessage", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        setFormState("SUCCESS");
      })
      .catch((err) => {
        setFormState("FAILED");
      });
  };

  useEffect(() => {
    fetch("https://backend-utils.onrender.com/wakeup", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, []);

  useEffect(() => {
    Object.keys(errors).length > 0 && setFormState("FAILED");
  }, [errors]);


  return (
    <ContactWrapper>
      <LeftColumn>
        <Spacer size={155} />
        <LeftColumnWrapper>
          <FormTitle>Let's talk</FormTitle>
          <FormSubTitle>(About food or photography)</FormSubTitle>
          <ContactTextWrapper>
            If you have an idea, if you want to build a project together or
            simply say hi, don't be shy and fill out the contact form.
          </ContactTextWrapper>
          <Image
            src={ContactImage}
            alt=""
          />
        </LeftColumnWrapper>
      </LeftColumn>
      <RightColumn>
        <Spacer size={155} />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormFieldWrapper className={errors.name ? "error" : ""}>
            <StyledLabel htmlFor="name-input">Name *</StyledLabel>
            <StyledInput
              id="name-input"
              {...register("name", { required: true })}
            />
          </FormFieldWrapper>
          <FormFieldWrapper className={errors.email ? "error" : ""}>
            <StyledLabel htmlFor="email-input">Email *</StyledLabel>
            <StyledInput
              id="email-input"
              {...register("email", { required: true })}
            />
          </FormFieldWrapper>
          <FormFieldWrapper className={errors.message ? "error" : ""}>
            <StyledLabel htmlFor="message-input">Message *</StyledLabel>
            <StyledTextArea
              id="message-input"
              {...register("message", { required: true })}
            />
          </FormFieldWrapper>
          {formState && <FormFeedback status={formState} />}
          <SubmitButton type="submit">Send</SubmitButton>
        </StyledForm>
      </RightColumn>
    </ContactWrapper>
  )
};
