import { ApolloProvider } from "@apollo/client";
import { BaseContainer } from "@components/BaseContainer";
import { Footer } from "@components/Footer";
import { Header } from "@components/Header";
import { NotFoundContent } from "@contents/404";
import { AboutContent } from "@contents/About";
import { ContactContent } from "@contents/Contact";
import { HomeContent } from "@contents/Home";
import { ProjectsContent } from "@contents/Projects";
import client from "@libs/apolloClient";
import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, Theme } from "./theme";
// import { WipContent } from "@contents/Wip";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <BaseContainer>
          <Router>
            <Header />
            <Switch>
              <Route path="/home">
                <HomeContent />
              </Route>
              <Route path="/about">
                <AboutContent />
              </Route>
              <Route exact path={"/projects"}>
                <ProjectsContent />
              </Route>
              <Route path={"/projects/:id"}>
                <ProjectsContent />
              </Route>
              <Route path="/contact">
                <ContactContent />
              </Route>
              {/* <Route path="/wip">
                <WipContent />
              </Route> */}
              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              <Route path="*">
                <NotFoundContent />
              </Route>
            </Switch>
            <Footer />
          </Router>
          <CookieConsent
            location="bottom"
            buttonText="Sure man!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
          </CookieConsent>
        </BaseContainer>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
