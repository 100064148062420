import { useQuery } from "@apollo/client";
import { Error } from "@components/ErrorHandler";
import { Loader } from "@components/Loader";
import { TESTIMONIALS } from "@graphql/queries";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css";
import { TestimonialsCarouselContainer } from "./styles";
import { TestimonialDetails } from "./Testimonial";

SwiperCore.use([Pagination]);

const pagination = {
  clickable: false,
  dynamicMainBullets: 3,
  renderBullet: (index: number, className: string) => {
    return `<span key="${index}" class="${className}"></span>`;
  },
};

export const TestimonialsCarousel = () => {
  const {
    loading,
    error,
    data: testimonials,
  } = useQuery(TESTIMONIALS, { variables: {} });

  if (loading) return <Loader />;
  if (error) return <Error />;

  return (
    testimonials.testimonials.nodes && (
      <TestimonialsCarouselContainer>
        <Swiper
          slidesPerView={1}
          spaceBetween={window.innerWidth > 820 ? 100 : 50} //25 on mobile
          pagination={pagination}
          centeredSlides={true}
          loop={true}
          autoplay={{ delay: 8000 }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {testimonials.testimonials.nodes.map(
            (testimonial: any, i: number) => (
              <SwiperSlide key={i}>
                {({ isActive }) => (
                  <TestimonialDetails
                    isActive={isActive}
                    details={testimonial}
                  />
                )}
              </SwiperSlide>
            )
          )}
        </Swiper>
      </TestimonialsCarouselContainer>
    )
  );
};
