import { useEffect } from "react";
import { ProjectImageGallery } from "./ProjectImageGallery";
import {
  ProjectDescription, ProjectDescriptionWrapper, ProjectDetailsWrapper, ProjectGalleryWrapper, ProjectMainPicture, ProjectTitle
} from "./styles";

export const ProjectDetails: React.FC<any> = ({ project }) => {
  useEffect(() => {
    const body = document.querySelector("#root");
    if (!body) return;
    body.scrollIntoView({
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <ProjectDetailsWrapper>
        <ProjectMainPicture
          src={`${project?.featuredImage?.node.sourceUrl}`}
        />
        <ProjectDescriptionWrapper>
          <ProjectTitle>{project.title}</ProjectTitle>
          <ProjectDescription
            dangerouslySetInnerHTML={{
              __html: project.content || "",
            }}
          />
        </ProjectDescriptionWrapper>
      </ProjectDetailsWrapper>
      <ProjectGalleryWrapper>
        <ProjectImageGallery gallery={project.gallery.gallery} />
      </ProjectGalleryWrapper>
    </>
  );
};
