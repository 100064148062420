import Be from "@assets/icons/be.svg";
import IG from "@assets/icons/ig.svg";
import { Image } from "@components/Image";
import { Logo } from "@components/Logo";
import { device, pxToRem } from "@libs/index";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination, Autoplay]);

const HeroCarouselContainer = styled.main`
  position: relative;
`;

const SocialIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: fit-content;
  margin-left: auto;
  margin-top: ${pxToRem(8)};
`;

const SocialIcon = styled.img`
  cursor: pointer;
`;

const CarouselContainer = styled.div`
  height: calc(100% - pxToRem(65.7));
  width: 100%;
  overflow: hidden;
  position: relative;

  .swiper {
    height: 100%;
    position: unset;

    .swiper-wrapper {
      @media ${device.tablet} {
        max-height: ${pxToRem(1000)};
      }
    }

    .swiper-slide {
      min-height: 100%;
      min-width: 100%;
      text-align: right;

      img {
        max-width: 95%;
        @media ${device.desktop} {
          max-width: 95%;
        }
        @media ${device.tablet} {
          max-width: 100%;
        }
      }
    }

    .swiper-pagination-bullets {
      position: absolute;
      top: ${pxToRem(0)};
      left: ${pxToRem(0)};
      width: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      height: 100%;

      .swiper-pagination-bullet {
        height: ${pxToRem(120)};
        border-radius: 0;
        width: 2px;

        &.swiper-pagination-bullet-active {
          background: black;
          width: 4px;
          left: -2px;
        }
      }
    }
  }
`;

const HeroWrapper = styled.div`
  position: absolute;
  top: ${pxToRem(150)};
  z-index: 2;
  isolation: isolate;
  max-width: ${pxToRem(550)};
  left: ${pxToRem(125)};

  svg {
    width: 100%;
    min-width: ${pxToRem(650)};
    max-width: ${pxToRem(650)};
  }
  > span {
    font-size: ${({ theme }) => theme.fontSize.step2};
    @media ${device.mobile} {
      margin-bottom: ${pxToRem(80)};
      display: inline-block;
    }
  }
  @media ${device.tablet} {
    left: ${pxToRem(100)};
    max-width: ${pxToRem(680)};
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  bottom: ${pxToRem(-100)};
  left: ${pxToRem(0)};
  padding-top: ${pxToRem(15)};
  border-top: 2px solid ${({ theme }) => theme.colors.text};
`;

const pagination = {
  clickable: false,
  dynamicMainBullets: 3,
  renderBullet: (index: number, className: string) => {
    return `<span key=${index} class="${className}"></span>`;
  },
};

export const HeroCarousel = ({ homepageData }: any) => {
  return (
    <HeroCarouselContainer>
      <CarouselContainer>
        <Swiper pagination={pagination} autoplay={{ delay: 4000 }} loop>
          {homepageData.gallery.map((image: any, index: number) => (
            <SwiperSlide key={index}>
              <Image src={image.sourceUrl} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </CarouselContainer>
      <HeroWrapper>
        <Logo />
        <span>
          Hello, I'm a photographer working in food, architecture and product.
        </span>
        <StyledLink to="/contact">Contact me</StyledLink>
      </HeroWrapper>
      <SocialIconWrapper>
        <a
          href="https://www.instagram.com/adrianlungu__/"
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon src={IG} />
        </a>
        <a
          href="https://www.behance.net/adrian92lu8d62"
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon src={Be} />
        </a>
      </SocialIconWrapper>
    </HeroCarouselContainer>
  );
};
