import { TestimonialProps } from "@customTypes/custom";
import {
  Quote, TestimonialBody, TestimonialContainer, TestimonialHeader,
  TestimonialPicture
} from "./styles";

export const TestimonialDetails: React.FC<TestimonialProps> = ({
  isActive,
  details,
}) => {
  return (
    <TestimonialContainer isActive={isActive}>
      <Quote>
        <TestimonialHeader>
          <TestimonialPicture
            src={`${details.featuredImage.node.sourceUrl || ""}`}
          />
          {details.title}
        </TestimonialHeader>
        <TestimonialBody dangerouslySetInnerHTML={{ __html: details.excerpt }} />
      </Quote>
    </TestimonialContainer>
  );
};
