import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

export const baseUrl = "https://cms.nicholaslazzerini.com/adrianlungu";
const httplink = createHttpLink({
  uri: operation => `${baseUrl}/graphql?name=${operation.operationName}`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httplink),
  cache: new InMemoryCache(),
});

export default client;
