import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { pxToRem } from "../libs";

const FooterWrapper = styled.footer`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: ${pxToRem(15)};
  flex-shrink: 0;

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${pxToRem(4)};

    > * {
      padding: 0 ${pxToRem(8)};
    }
  }
`;

const CopyrightContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.text};
  padding-top: ${pxToRem(4)};
`;

const DevelopedContainer = styled.div`
  padding: ${pxToRem(35)} 0 ${pxToRem(35)};
  font-size: ${({ theme }) => theme.fontSize.step};
  margin-bottom: 1rem;
  a {
    display: inline;
    text-decoration: underline;
  }
`;

const Separator = styled.span``;

export const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <nav>
        <Link to="/home">Home</Link>
        <Separator>/</Separator>
        <Link to="/projects#food">Works</Link>
        <Separator>/</Separator>
        <Link to="/about">About</Link>
        <Separator>/</Separator>
        <Link to="/contact">Contact</Link>
      </nav>
      <CopyrightContainer>
        ADRIAN LUNGU &copy; copyright 2021<br />
        Partita Iva 03936081201
      </CopyrightContainer>
      <DevelopedContainer>
        Designed and developed with ❤️ by{" "}
        <a
          href="https://caterinaappignani.com"
          target="_blank"
          rel="noreferrer"
        >
          CaterinaAppignani
        </a>{" "}
        &amp;{" "}
        <a
          href="https://nicholaslazzerini.com"
          target="_blank"
          rel="noreferrer"
        >
          NicholasLazzerini
        </a>
      </DevelopedContainer>
    </FooterWrapper>
  );
};
