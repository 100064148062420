import { createGlobalStyle, DefaultTheme } from "styled-components";
import { pxToRem, device } from "@libs/index";

export const Theme: DefaultTheme = {
  /* @link https://utopia.fyi/type/calculator?c=320,14,1.2,1140,16,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

  fontSize: {
    step: "clamp(0.43rem, 0.33rem + 0.53vw, 0.96rem)",
    step0: "clamp(0.52rem, 0.26rem + 1.33vw, 1.20rem)",
    step1: "clamp(0.63rem, 0.28rem + 1.71vw, 1.50rem)",
    step2: "clamp(0.75rem, 0.31rem + 2.20vw, 1.88rem)",
    step3: "clamp(0.90rem, 0.34rem + 2.82vw, 2.34rem)",
    step4: "clamp(1.08rem, 0.36rem + 3.61vw, 2.93rem)",
    step5: "clamp(1.30rem, 0.37rem + 4.62vw, 3.66rem)",
    step6: "clamp(1.56rem, 0.38rem + 5.90vw, 4.58rem)",
    step7: "clamp(1.87rem, 0.36rem + 7.52vw, 5.72rem)",
  },
  colors: {
    secondary: "#D5D5D5",
    buttonText: "#F7FBFD",
    primary: "#2c738b",
    text: "#121213",
    body: "#F7FBFD",
    projectCardBackground: "#ffffff",
    siteBackground: "#FFFFFF",
  },
};

export const GlobalStyles = createGlobalStyle`
	html, body {
		margin: 0;
		padding: 0;
		font-size: clamp(0.88rem, 0.83rem + 0.24vw, 1.00rem);
		font-family: 'Open Sans', sans-serif;
		background: ${({ theme }) => theme.colors.siteBackground};
		color: ${({ theme }) => theme.colors.text}
	}

	html, body, #root {
		height: 100%;
		width: 100%;
	}

	#root {
		padding: ${pxToRem(47)} ${pxToRem(140)};
		height: calc(100% - ${pxToRem(47)});
  		width: 100vw;
		box-sizing: border-box;

		@media ${device.mobile} {
			padding: ${pxToRem(47)} ${pxToRem(35)};
			width: calc(100vw - ${pxToRem(35)});
		}

		@media ${device.tablet} {
			padding: ${pxToRem(47)} ${pxToRem(70)};
			width: 100vw;
		}

		@media ${device.desktop} {
			padding: ${pxToRem(47)} ${pxToRem(50)};
			width: 100vw;
		}
	}

	a {
		height: initial;
		display: flex;
		text-decoration: none;
		color: ${({ theme }) => theme.colors.text}
	}

	p {
		margin: 0
	}
`;
