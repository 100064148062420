interface LogoProps {
  width?: number | string;
}

export const Logo = ({ width = 150 }: LogoProps) => {
  return (
    <svg
      width={width}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 611.51 94.21"
    >
      <g className="st0">
        <path
          className="st1"
          d="M43.1,1.13l26.7,71.4H53.5l-5.4-15.9H21.4l-5.6,15.9H0l27-71.4H43.1z M44,44.93l-9-26.2h-0.2l-9.3,26.2H44z"
        />
        <path
          className="st1"
          d="M109.2,65.93c-1.67,2.8-3.85,4.82-6.55,6.05c-2.7,1.23-5.75,1.85-9.15,1.85c-3.87,0-7.27-0.75-10.2-2.25
		c-2.93-1.5-5.35-3.53-7.25-6.1c-1.9-2.57-3.33-5.52-4.3-8.85c-0.97-3.33-1.45-6.8-1.45-10.4c0-3.47,0.48-6.82,1.45-10.05
		c0.97-3.23,2.4-6.08,4.3-8.55c1.9-2.46,4.28-4.45,7.15-5.95c2.87-1.5,6.2-2.25,10-2.25c3.07,0,5.98,0.65,8.75,1.95
		c2.77,1.3,4.95,3.22,6.55,5.75h0.2v-26h14.2v71.4h-13.5v-6.6H109.2z M108.6,40.38c-0.4-1.96-1.08-3.7-2.05-5.2
		c-0.97-1.5-2.22-2.72-3.75-3.65c-1.53-0.93-3.47-1.4-5.8-1.4c-2.33,0-4.3,0.47-5.9,1.4c-1.6,0.93-2.88,2.17-3.85,3.7
		c-0.97,1.53-1.67,3.28-2.1,5.25c-0.43,1.97-0.65,4.02-0.65,6.15c0,2,0.23,4,0.7,6c0.47,2,1.22,3.79,2.25,5.35
		c1.03,1.57,2.33,2.83,3.9,3.8c1.57,0.97,3.45,1.45,5.65,1.45c2.33,0,4.28-0.47,5.85-1.4c1.57-0.93,2.82-2.18,3.75-3.75
		c0.93-1.57,1.6-3.35,2-5.35s0.6-4.07,0.6-6.2C109.2,44.39,109,42.35,108.6,40.38z"
        />
        <path
          className="st1"
          d="M145.1,20.83v9.6h0.2c0.67-1.6,1.57-3.08,2.7-4.45c1.13-1.37,2.43-2.53,3.9-3.5c1.47-0.97,3.03-1.72,4.7-2.25
		c1.67-0.53,3.4-0.8,5.2-0.8c0.93,0,1.97,0.17,3.1,0.5v13.2c-0.67-0.13-1.47-0.25-2.4-0.35c-0.93-0.1-1.83-0.15-2.7-0.15
		c-2.6,0-4.8,0.43-6.6,1.3c-1.8,0.87-3.25,2.05-4.35,3.55s-1.88,3.25-2.35,5.25c-0.47,2-0.7,4.17-0.7,6.5v23.3h-14.2v-51.7H145.1z"
        />
        <path
          className="st1"
          d="M168.9,12.83V1.13h14.2v11.7H168.9z M183.1,20.83v51.7h-14.2v-51.7H183.1z"
        />
        <path
          className="st1"
          d="M191.7,36.73c0.2-3.33,1.03-6.1,2.5-8.3c1.47-2.2,3.33-3.97,5.6-5.3c2.27-1.33,4.82-2.28,7.65-2.85
		c2.83-0.57,5.68-0.85,8.55-0.85c2.6,0,5.23,0.18,7.9,0.55c2.67,0.37,5.1,1.08,7.3,2.15c2.2,1.07,4,2.55,5.4,4.45
		c1.4,1.9,2.1,4.42,2.1,7.55v26.9c0,2.33,0.13,4.57,0.4,6.7c0.27,2.13,0.73,3.73,1.4,4.8h-14.4c-0.27-0.8-0.48-1.62-0.65-2.45
		c-0.17-0.83-0.28-1.68-0.35-2.55c-2.27,2.33-4.93,3.97-8,4.9c-3.07,0.93-6.2,1.4-9.4,1.4c-2.47,0-4.77-0.3-6.9-0.9
		c-2.13-0.6-4-1.53-5.6-2.8c-1.6-1.27-2.85-2.87-3.75-4.8c-0.9-1.93-1.35-4.23-1.35-6.9c0-2.93,0.52-5.35,1.55-7.25
		c1.03-1.9,2.37-3.42,4-4.55c1.63-1.13,3.5-1.98,5.6-2.55c2.1-0.57,4.22-1.02,6.35-1.35c2.13-0.33,4.23-0.6,6.3-0.8
		c2.07-0.2,3.9-0.5,5.5-0.9s2.87-0.98,3.8-1.75c0.93-0.77,1.37-1.88,1.3-3.35c0-1.53-0.25-2.75-0.75-3.65c-0.5-0.9-1.17-1.6-2-2.1
		c-0.83-0.5-1.8-0.83-2.9-1c-1.1-0.17-2.28-0.25-3.55-0.25c-2.8,0-5,0.6-6.6,1.8c-1.6,1.2-2.53,3.2-2.8,6H191.7z M224.5,47.23
		c-0.6,0.53-1.35,0.95-2.25,1.25s-1.87,0.55-2.9,0.75c-1.03,0.2-2.12,0.37-3.25,0.5c-1.13,0.13-2.27,0.3-3.4,0.5
		c-1.07,0.2-2.12,0.47-3.15,0.8c-1.03,0.33-1.93,0.78-2.7,1.35c-0.77,0.57-1.38,1.29-1.85,2.15c-0.47,0.87-0.7,1.97-0.7,3.3
		c0,1.27,0.23,2.33,0.7,3.2c0.47,0.87,1.1,1.55,1.9,2.05s1.73,0.85,2.8,1.05c1.07,0.2,2.17,0.3,3.3,0.3c2.8,0,4.97-0.47,6.5-1.4
		c1.53-0.93,2.67-2.05,3.4-3.35c0.73-1.3,1.18-2.62,1.35-3.95c0.17-1.33,0.25-2.4,0.25-3.2V47.23z"
        />
        <path
          className="st1"
          d="M261.2,20.83v7.2h0.3c1.8-3,4.13-5.18,7-6.55c2.87-1.37,5.8-2.05,8.8-2.05c3.8,0,6.92,0.52,9.35,1.55
		c2.43,1.03,4.35,2.47,5.75,4.3c1.4,1.83,2.38,4.07,2.95,6.7c0.57,2.63,0.85,5.55,0.85,8.75v31.8H282v-29.2c0-4.27-0.67-7.45-2-9.55
		c-1.33-2.1-3.7-3.15-7.1-3.15c-3.87,0-6.67,1.15-8.4,3.45c-1.73,2.3-2.6,6.08-2.6,11.35v27.1h-14.2v-51.7H261.2z"
        />
        <path className="st1" d="M348,1.13v58.2h34.8v13.2h-50.5V1.13H348z" />
        <path
          className="st1"
          d="M423.09,72.53v-7.2h-0.3c-1.8,3-4.13,5.17-7,6.5c-2.87,1.33-5.8,2-8.8,2c-3.8,0-6.92-0.5-9.35-1.5
		c-2.43-1-4.35-2.42-5.75-4.25c-1.4-1.83-2.38-4.07-2.95-6.7c-0.57-2.63-0.85-5.55-0.85-8.75v-31.8h14.2v29.2
		c0,4.27,0.67,7.45,2,9.55c1.33,2.1,3.7,3.15,7.1,3.15c3.87,0,6.67-1.15,8.4-3.45c1.73-2.3,2.6-6.08,2.6-11.35v-27.1h14.2v51.7
		H423.09z"
        />
        <path
          className="st1"
          d="M458.89,20.83v7.2h0.3c1.8-3,4.13-5.18,7-6.55c2.87-1.37,5.8-2.05,8.8-2.05c3.8,0,6.92,0.52,9.35,1.55
		c2.43,1.03,4.35,2.47,5.75,4.3c1.4,1.83,2.38,4.07,2.95,6.7c0.57,2.63,0.85,5.55,0.85,8.75v31.8h-14.2v-29.2
		c0-4.27-0.67-7.45-2-9.55c-1.33-2.1-3.7-3.15-7.1-3.15c-3.87,0-6.67,1.15-8.4,3.45c-1.73,2.3-2.6,6.08-2.6,11.35v27.1h-14.2v-51.7
		H458.89z"
        />
        <path
          className="st1"
          d="M551.54,76.33c-0.57,2.67-1.78,5.15-3.65,7.45c-1.87,2.3-4.57,4.25-8.1,5.85c-3.53,1.6-8.27,2.4-14.2,2.4
		c-2.53,0-5.12-0.32-7.75-0.95c-2.63-0.63-5.03-1.62-7.2-2.95c-2.17-1.33-3.97-3.05-5.4-5.15c-1.43-2.1-2.25-4.62-2.45-7.55h14.1
		c0.67,2.67,1.96,4.52,3.9,5.55c1.93,1.03,4.17,1.55,6.7,1.55c4,0,6.92-1.2,8.75-3.6c1.83-2.4,2.72-5.43,2.65-9.1v-6.8h-0.2
		c-1.53,2.73-3.72,4.75-6.55,6.05c-2.83,1.3-5.82,1.95-8.95,1.95c-3.87,0-7.2-0.68-10-2.05c-2.8-1.37-5.1-3.23-6.9-5.6
		c-1.8-2.37-3.12-5.13-3.95-8.3c-0.83-3.17-1.25-6.52-1.25-10.05c0-3.33,0.48-6.55,1.45-9.65c0.97-3.1,2.38-5.83,4.25-8.2
		c1.87-2.37,4.18-4.25,6.95-5.65c2.77-1.4,5.95-2.1,9.55-2.1c3.4,0,6.38,0.63,8.95,1.9c2.57,1.27,4.72,3.4,6.45,6.4h0.2v-6.9h13.5
		v48.4C552.39,71.3,552.11,73.66,551.54,76.33z M532.09,59.13c1.53-0.8,2.8-1.87,3.8-3.2c1-1.33,1.75-2.85,2.25-4.55
		c0.5-1.7,0.75-3.48,0.75-5.35c0-2.13-0.2-4.15-0.6-6.05c-0.4-1.9-1.07-3.58-2-5.05c-0.93-1.47-2.17-2.63-3.7-3.5
		c-1.53-0.87-3.47-1.3-5.8-1.3c-2,0-3.72,0.4-5.15,1.2c-1.43,0.8-2.63,1.88-3.6,3.25c-0.97,1.37-1.67,2.93-2.1,4.7
		c-0.43,1.77-0.65,3.62-0.65,5.55c0,1.87,0.18,3.72,0.55,5.55c0.37,1.83,1,3.48,1.9,4.95c0.9,1.47,2.08,2.67,3.55,3.6
		c1.47,0.93,3.3,1.4,5.5,1.4C528.79,60.33,530.56,59.93,532.09,59.13z"
        />
        <path
          className="st1"
          d="M596.79,72.53v-7.2h-0.3c-1.8,3-4.13,5.17-7,6.5c-2.87,1.33-5.8,2-8.8,2c-3.8,0-6.92-0.5-9.35-1.5
		c-2.43-1-4.35-2.42-5.75-4.25c-1.4-1.83-2.38-4.07-2.95-6.7c-0.57-2.63-0.85-5.55-0.85-8.75v-31.8h14.2v29.2
		c0,4.27,0.67,7.45,2,9.55c1.33,2.1,3.7,3.15,7.1,3.15c3.87,0,6.67-1.15,8.4-3.45c1.73-2.3,2.6-6.08,2.6-11.35v-27.1h14.2v51.7
		H596.79z"
        />
      </g>
    </svg>
  );
};
