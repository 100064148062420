import { useQuery } from "@apollo/client";
import { Error } from "@components/ErrorHandler";
import { StyledLink } from "@components/HeroCarousel";
import { Image } from "@components/Image";
import { Loader } from "@components/Loader";
import { Logo } from "@components/Logo";
import { Spacer } from "@components/Spacer";
import { ABOUT } from "@graphql/queries";
import { device, pxToRem } from "@libs/index";
import { useEffect } from "react";
import styled from "styled-components/macro";

const AboutWrapper = styled.main`
  display: grid;
  gap: ${pxToRem(110)};
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSize.step0};
  grid-template-columns: ${pxToRem(450)} 1fr;

  @media ${device.desktop} {
    grid-template-columns: ${pxToRem(550)} 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.fontSize.step1};
  }
`;

const LogoContainer = styled.div`
  position: relative;
  height: 100%;
  order: 0;
  flex-direction: column;
  justify-content: space-between;
  svg {
    display: flex;
  }

  @media ${device.tablet} {
    order: 1;
    svg {
      height: auto;
      display: none;
    }
  }
`;

const StyledLinkCustom = styled(StyledLink)`
  position: unset;
  width: fit-content;
  margin-top: ${pxToRem(75)};

  @media ${device.tablet} {
    margin-top: 0;
  }
`;

const AboutContainer = styled.div`
  padding: 0 ${pxToRem(71)};
  min-height: 86vh;
  img {
    width: 100%;
    margin: ${pxToRem(10)} 0 ${pxToRem(85)};
  }
`;

export const AboutContent = () => {
  const {
    loading,
    error,
    data: aboutDetails,
  } = useQuery(ABOUT, { variables: {} });

  useEffect(() => {
    const body = document.querySelector("#root");
    if (!body) return;
    body.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  if (loading) return <Loader />;
  if (error) return <Error />;

  const description = aboutDetails.about.content || "";
  const photo = `${aboutDetails.about.featuredImage.node.sourceUrl}`;

  return (
    <AboutContainer>
      <Spacer size={150} />
      <AboutWrapper>
        <LogoContainer>
          <Logo width={"auto"} />
          <StyledLinkCustom to="/contact">Let's talk</StyledLinkCustom>
        </LogoContainer>
        <span
          dangerouslySetInnerHTML={{
            __html: description || "",
          }}
        ></span>
      </AboutWrapper>
      <Spacer size={100} />
      <Image src={photo} alt="Adrian lungu - here I am" />
    </AboutContainer>
  );
};
