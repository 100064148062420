export const pxToRem = (pxValue: number) => {
  const ratio = 16;

  if (Array.isArray(pxValue)) {
    pxValue = pxValue[0];
  }

  return `${pxValue / ratio}vw`;
};

export const size = {
  tablet: 768,
  desktop: 1024,
  fullHD: 1440,
};

export const device = {
  mobile: `(max-width: ${size.tablet}px)`,
  tablet: `(max-width: ${size.desktop}px)`,
  desktop: `(max-width: ${size.fullHD}px)`,
};
