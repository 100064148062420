import { gql } from "@apollo/client";

export const PROJECTS = gql`
  {
    projects(first: 100) {
      nodes {
        title
        projecttype {
          projecttype
        }
        id
        content
        uri
        featuredImage {
          node {
            id
            link
            fileSize
            mediaItemUrl
            altText
            date
            sourceUrl
            mediaType
            mimeType
            srcSet
          }
        }
        gallery {
          gallery {
            id
            link
            fileSize
            mediaItemUrl
            altText
            date
            sourceUrl
            mediaType
            mimeType
            srcSet
          }
        }
      }
    }
  }
`;

export const PROJECTDETAILS = gql`
  query Project($id: ID!) {
    project(id: $id) {
      title
      projecttype {
        projecttype
      }
      id
      content
      uri
      featuredImage {
        node {
          id
          link
          fileSize
          mediaItemUrl
          altText
          date
          sourceUrl
          mediaType
          mimeType
          srcSet
        }
      }
      gallery {
        gallery {
          id
          link
          fileSize
          mediaItemUrl
          altText
          date
          sourceUrl
          mediaType
          mimeType
          srcSet
        }
      }
    }
  }
`;

export const TESTIMONIALS = gql`
  {
    testimonials {
      nodes {
        title
        id
        excerpt
        featuredImage {
          node {
            id
            link
            fileSize
            mediaItemUrl
            altText
            date
            sourceUrl
            mediaType
            mimeType
            srcSet
          }
        }
      }
    }
  }
`;

export const ABOUT = gql`
  {
    about(id: "54", idType: DATABASE_ID) {
      id
      title
      content
      featuredImage {
        node {
          id
          link
          fileSize
          mediaItemUrl
          altText
          date
          sourceUrl
          mediaType
          mimeType
          srcSet
        }
      }
    }
  }
`;

export const HOMEPAGE = gql`
  {
    homepageCarousels {
      nodes {
        title
        id
        uri
        gallery {
          gallery {
            id
            link
            fileSize
            mediaItemUrl
            altText
            date
            sourceUrl
            mediaType
            mimeType
            srcSet
          }
        }
      }
    }
  }
`;

export const COLLABS = gql`
  {
    collabs(first: 50) {
      nodes {
        title
        featuredImage {
          node {
            id
            link
            fileSize
            mediaItemUrl
            altText
            date
            sourceUrl
            mediaType
            mimeType
            srcSet
          }
        }
      }
    }
  }
`;
