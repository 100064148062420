import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { device, pxToRem } from "../libs";
import { Logo } from "./Logo";

const HeaderWrapper = styled.header`
  height: ${pxToRem(65.7)};
  background: ${({ theme }) => theme.colors.siteBackground};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  a {
    display: unset;

    > svg {
      display: block;
    }
  }
`;

const MobileMenu = styled.nav`
  display: none;

  @media ${device.tablet} {
    display: flex;
  }
`;

const MobileMenuWrapper = styled.div`
  display: block;
  width: 30%;
  position: relative;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  padding: ${pxToRem(200)} ${pxToRem(50)} 0;
  list-style: none;
  z-index: 9;
  border-left: 1px solid black;
  > * {
    text-align: center;
    margin: 0 ${pxToRem(15)};
    padding: 0 ${pxToRem(20)} ${pxToRem(7)};
    color: ${({ theme }) => theme.colors.text};
    letter-spacing: 2.5px;
    line-height: 4;

    a {
      &.active {
        font-weight: bold;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${device.mobile} {
    width: 40%;
  }
`;

const Menu = styled.ul`
  list-style: none;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  padding-left: ${pxToRem(152)};
  display: flex;

  > * {
    text-align: center;
    margin: 0 ${pxToRem(15)};
    padding: 0 ${pxToRem(20)} ${pxToRem(7)};
    color: ${({ theme }) => theme.colors.text};
    letter-spacing: 2.5px;

    a {
      &.active {
        font-weight: bold;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
  @media ${device.tablet} {
    display: none;
  }
`;

const Hamburger = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 10;
`;

const Bar1 = styled.div`
  width: 20px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
  &.change {
    -webkit-transform: rotate(-45deg) translate(-4.5px, 3px);
    transform: rotate(-45deg) translate(-4.5px, 3px);
  }
`;
const Bar2 = styled.div`
  width: 20px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
  &.change {
    opacity: 0;
  }
`;

const Bar3 = styled.div`
  width: 20px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
  &.change {
    -webkit-transform: rotate(45deg) translate(-5px, -4px);
    transform: rotate(45deg) translate(-5px, -4px);
  }
`;

interface MenuItemsProps {
  setOpen: any;
}

const MenuItems = ({ setOpen }: MenuItemsProps) => (
  <>
    <li>
      <NavLink onClick={() => setOpen(false)} to="/projects#food">
        Works
      </NavLink>
    </li>
    <li>
      <NavLink onClick={() => setOpen(false)} to="/about">
        About
      </NavLink>
    </li>
    <li>
      <NavLink onClick={() => setOpen(false)} to="/contact">
        Contact
      </NavLink>
    </li>
  </>
);

export const Header: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <HeaderWrapper>
      <div>
        <Link to="/home">
          <Logo width={window.innerWidth < 500 ? 120 : 200} />
        </Link>
      </div>
      <nav>
        <MobileMenu>
          <Hamburger onClick={() => setOpen(!open)}>
            <Bar1 className={open ? "change" : ""} />
            <Bar2 className={open ? "change" : ""} />
            <Bar3 className={open ? "change" : ""} />
          </Hamburger>
          {open && (
            <MobileMenuWrapper>
              <li>
                <NavLink onClick={() => setOpen(false)} to="/home">
                  Home
                </NavLink>
              </li>
              <MenuItems setOpen={setOpen} />
            </MobileMenuWrapper>
          )}
        </MobileMenu>
        <Menu>
          <MenuItems setOpen={setOpen} />
        </Menu>
      </nav>
    </HeaderWrapper>
  );
};
